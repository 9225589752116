<template>
  <div>
    <ch-layout page search button>
      <template #button>
        <ch-button @click="$router.push({name:'commodityAdd'})" style="margin-right: 10px;">新增</ch-button>
        <ch-button @click="openServiceTag" style="margin-right: 10px;">服务标签库</ch-button>
        <ch-button @click="openImgTemplate">头图模板库</ch-button>
      </template>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="$refs.commodityWatch.openAddWatch(scope.row,scope.row.id)">预览</ch-button>
            <ch-button type="link"  @click="$router.push({name:'commodityEdit',query:{id:scope.row.id}})">编辑</ch-button>
            <ch-button type="up" v-if="scope.row.status===1" @click="handleUpDown(scope.row)">上架</ch-button>
            <ch-button type="down" v-if="scope.row.status===2" @click="handleUpDown(scope.row)">下架</ch-button>
            <ch-button type="link" v-if="scope.row.status===2" @click="handleCode(scope.row)">二维码</ch-button>
            <ch-button type="delete" v-if="scope.row.status===0||scope.row.status===1" @click="handleDelete(scope.row)">删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <code-modal ref="codeModal"></code-modal>
    <service-tag ref="tagsModal"></service-tag>
    <commodity-watch ref="commodityWatch" />
  </div>
</template>

<script>
import { handleDelete } from "@/utils/utils";
import dayjs from "dayjs";
const codeModal = () => import("./qrCodeModal");
const serviceTag = () => import("./serviceTag");
const commodityWatch = () => import("./commodityWatch.vue")

export default {
  components:{codeModal,serviceTag,commodityWatch},
  data(){
    return{
      // 搜索相关
      searchForm: {
        status:null,
        name:null,
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      disabled: true,
      communityList:[],
      communityGroup:[],
      typeOption:[{
        name:'家政',
        id:1,
      }],
      selectedOptions: [],
      classifyOption:[],
      statusOptions:{
        0:'草稿',
        1:'已下架',
        2:'已上架',
      },
      treeSelect:{
        add: false,
        edit: false,
        serviceTag: false,
        up: false,
        down: false,
        qrcode: false,
        delete: false,
      },
    }
  },
  computed:{
    searchOption: function (){
      return [
        {type: "select", label: "商品状态", prop: "status", placeholder: "请选择",
          name: "label", value: "value", option: [
            {value:0, label: "草稿"},{ value: 1, label: "已下架" }, { value: 2, label: "已上架" },
          ]},
        {type: 'input', label: '服务名称', prop: 'name', placeholder: "请输入",},
        {type: "select", label: "一级分类", prop: "firstClassifyId", placeholder: "请选择",
          name: "name", value: "id", option: this.classifyOption, props: { collapseTags: true} },
        {type: "select", label: "二级分类", prop: "secondClassifyId", placeholder: "请选择",
          name: "name", value: "id", option: this.filterSelectedOptions, props: { collapseTags: true} },
        {type: 'time', label: '创建时间', prop: 'time',props: {type: 'daterange'},format:"yyyy-MM-dd", placeholder: "请输入",},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.resetSearch},
          ]},
      ]
    },
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "ID", prop: "id",width: "70",showOverflowTooltip:true,},
        { column: "type", label: "商品编号", prop: "number",showOverflowTooltip: true,},
        { column: "text", label: "服务名称", prop: "goodsName", showOverflowTooltip: true,},
        { column: "text", label: "推广Tip", prop: "tip", showOverflowTooltip: true,},
        { column: "text", label: "一级分类", prop: "first", showOverflowTooltip: true,},
        { column: "text", label: "二级分类", prop: "second", showOverflowTooltip: true,},
        { column: "text", label: "创建时间", prop: "createTime", showOverflowTooltip: true,},
        { column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        { column: "slot", label: "操作", slotName: "handle", width: "180" },
      ];
    },
    filterSelectedOptions: function () {
      if(this.searchForm.firstClassifyId){
        return this.selectedOptions.filter(item => item.parentId === this.searchForm.firstClassifyId)
      }else {
        return this.selectedOptions
      }
    }
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      this.getTableList()
    },
    resetSearch() {
      this.getTableList({})
    },
    async getClassify(){
      await this.$curl.get('/hm/classify/firstList').then(res=>{
        this.classifyOption = res.data
      })
    },
    async getSecondList(){
      await this.$curl.get('/hm/classify/secondList').then(res=>{
        this.selectedOptions = res.data
      })
    },
    // 列表数据
    async getTableList(params=this.searchForm) {
      await this.getClassify()
      await this.getSecondList()
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/goods/list",params).then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          return {
            ...item,
            _status:this.statusOptions[item.status]||'一',
            createTime:dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    // 上下架
    handleUpDown(row) {
      let text =  row.status===1? '上架成功':'下架成功'
      let status = row.status===1? 2:1
      this.$curl.post(`/hm/goods/updateStatus/${row.id}/${status}`)
          .then(() => {
            this.$message.success(text);
            this.getTableList()
          })
    },
    // 删除
    handleDelete(row) {
      handleDelete("/hm/goods/del/", row.id).then(() => {
        this.getTableList();
      });
    },
    // 二维码
    handleCode(row) {
      this.$refs.codeModal.openCode(row.id)
    },
    // 服务标签库
    openServiceTag(){
      this.$refs.tagsModal.openTagsList()
    },
    // 头图模板库
    openImgTemplate(){
      this.$router.push({name:'imgTemplate'})
    }
  }
}
</script>

<style scoped>

</style>